<template>
    <div class="full-height-layout fill">
        <landing
            :pages="pages"
        ></landing>
    </div>
</template>

<script>
import Landing from '../../components/Landing'
export default {
    name: 'ComplaintsGlobalLanding',
    components: { Landing },
    data () {
        return {
            pages: [
                {
                    title: 'Supplier complaints',
                    description: `View complaints related with suppliers`,
                    route: {
                        name: 'ComplaintsGlobalOverview',
                        params: {
                            complaintSection: 'suppliers',
                            complaintStatus: 'AWAITING_SUPPLIER',
                        },
                    },
                    actionName: 'SupplierCentral/listComplaintsForVirtualSupplier',
                },
                {
                    title: 'Carrier complaints',
                    description: `View complaints related with carriers`,
                    route: {
                        name: 'ComplaintsGlobalOverview',
                        params: {
                            complaintSection: 'carriers',
                            complaintStatus: 'APPROVED',
                        },
                    },
                    actionName: 'SupplierCentral/listComplaintsForVirtualSupplier',
                },
                {
                    title: 'Artwork complaints',
                    description: `View complaints related with artwork`,
                    route: {
                        name: 'ComplaintsGlobalOverview',
                        params: {
                            complaintSection: 'artwork',
                            complaintStatus: 'APPROVED',
                        },
                    },
                    actionName: 'SupplierCentral/listComplaintsForVirtualSupplier',
                },
                {
                    title: 'Supply chain complaints',
                    description: `View complaints related with Supply Chain`,
                    route: {
                        name: 'ComplaintsGlobalOverview',
                        params: {
                            complaintSection: 'supplyChain',
                            complaintStatus: 'APPROVED',
                        },
                    },
                    actionName: 'SupplierCentral/listComplaintsForVirtualSupplier',
                },
            ],
        }
    },
}
</script>
