<template>
    <div class="container">
        <div class="row">
            <template v-for="(page, key) in pages">
                <div :key="key" v-if="isAllowedTo(page.actionName)" class="col-4">
                    <mercur-card class="mb-4 page-card d-flex flex-column justify-content-between">
                        <div>
                            <h2 class="mt-2 font-weight-normal">{{page.title}}</h2>
                            <p class="mt-3">{{page.description}}</p>
                        </div>
                        <div class="d-flex justify-content-end">
                            <mercur-button class="btn text-uppercase" v-bind="page.attrs" :to="page.route">go to {{page.title}}</mercur-button>
                        </div>
                    </mercur-card>
                </div>
            </template>
        </div>
    </div>
</template>
<script>
export default {
    name: 'Landing',
    props: {
        pages: {
            required: true,
        },
    },
}
</script>
<style>
    .page-card {
        min-height: 200px;
    }
</style>
